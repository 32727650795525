// store/auth.js

import axios from "axios";
import { apis } from "./apis/user";
import router from "../router/routes";

const state = {
  // Additional state if needed
  user: null,
  token: null,
  userTransationHistory: null,
  userProfilePicture: null,
  isLoggedIn: false,
  componentLayout: "",
};

const mutations = {
  SET_LOGIN_DETAILS(state, data) {
    state.user = data;
  },
  SET_USER_TRANSACTION_HISTORY(state, data) {
    state.userTransationHistory = data.data;
  },
  SET_USER_PROFILE_PICTURE(state, data) {
    state.userProfilePicture = data;
  },
  SET_USER_AUTHS(state, data) {
    state.isLoggedIn = data.isLoggedIn;
    state.token = data.token;
  },
  SET_COMPONENT_LAYOUT(state, data) {
    state.componentLayout = data.layout;
  },
};

const actions = {
  // Additional actions if needed
  login({ dispatch, commit }, payload) {

    var formData = {
      email: payload.email,
      password: payload.password,
    }
    // formData.append("email", payload.email);
    // formData.append("password", payload.password);


    return new Promise((resolve, reject) => {
      axios
        .post(axios.defaults.baseURL + apis.LOGIN, formData)
        .then((res) => {
          localStorage.setItem("token", res.data.token);
          localStorage.setItem("user", JSON.stringify(res.data.result));

          const response = res.data.result;

          router.push("/").then(() => {
            router.go();
          });
          dispatch("checkErrorAndSendToast", ["Succssfully Login", "success"]);

          resolve(res);
        })
        .catch((err) => {
          dispatch("checkErrorAndSendToast", [err.response, "error"]);
          reject(err);
        });
    });
  },
  getUserById({ dispatch, commit }, payload) {
    const user = JSON.parse(localStorage.getItem("user"));

    if (user) {
      return new Promise((resolve, reject) => {
        axios
          .get(axios.defaults.baseURL + apis.GET_USER_BY_ID + user.id, {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
          })
          .then((res) => {
            localStorage.setItem("user", JSON.stringify(res.data));
            const response = res.data;

            let user = {
              address: response.address,
              avatar: response.avatar,
              updated_at: response.updated_at,
              created_at: response.created_at,
              email: response.email,
              id: response.id,
              title: response.title,
              name: response.name,
              phone: response.phone,
              country: response.country,
              state: response.state,
              zip_code: response.zip_code,
              about: response.about,
              social_media_links: response.social_media_links,
              role_id: response.role_id,
              token: res.data.token,
            };
            commit("SET_LOGIN_DETAILS", user);
            commit("SET_USER_AUTHS", {
              isLoggedIn: true,
              token: res.data.token,
            });

            resolve(res);
          })
          .catch((err) => {
            dispatch("checkErrorAndSendToast", [err.response, "error"]);
            reject(err);
          });
      });
    } 
  },
  loginWithGoogle({dispatch, commit }, payload) {
    var formData = new FormData();
    formData.append("name", payload.name);
    formData.append("email", payload.email);
    formData.append("avatar", payload.photoURL);
    formData.append("role_id", payload.roles);
    return new Promise((resolve, reject) => {
      axios
        .post(axios.defaults.baseURL + apis.LOGIN_WITH_GOOGLE, formData)
        .then((res) => {
          localStorage.setItem("token", res.data.token);
          localStorage.setItem("user", JSON.stringify(res.data.result));

          const response = res.data.result;

          if (response.role_id == "2" || response.role_id === 2) {
            let user = {
              address: response.address,
              avatar: response.avatar,
              created_at: response.created_at,
              email: response.email,
              id: response.id,
              title: response.title,
              name: response.name,
              phone: response.phone,
              country: response.country,
              state: response.state,
              zip_code: response.zip_code,
              about: response.about,
              social_media_links: response.social_media_links,
              role_id: response.role_id,
              token: res.data.token,
            };
            commit("SET_LOGIN_DETAILS", user);
            commit("SET_USER_AUTHS", {
              isLoggedIn: true,
              token: res.data.token,
            });

            router.push("/").then(() => {
              router.go();
            });
          }
          dispatch("checkErrorAndSendToast", ["Succssfully Login", "success"]);

          resolve(res);

          commit("UPDATE_LOADING_STATE", false);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  userRegister({ dispatch }, payload) {

    var formData = new FormData();
    formData.append("title", payload.title);
    formData.append("name", payload.name);
    formData.append("email", payload.email);
    // formData.append("phone", payload.phone);
    formData.append("password", payload.password);
    formData.append("confirm_password", payload.confirmPassword);
    formData.append("role", "2");
    return new Promise((resolve, reject) => {
      axios
        .post(axios.defaults.baseURL + apis.REGISTER_USER, formData)
        .then((res) => {
          router.push("/login-user").then(() => {
            router.go();
          });

          resolve(res);
          dispatch("checkErrorAndSendToast", [
            res.data.message,
            "success",
          ]);
        })
        .catch((err) => {
          reject(err);
          dispatch("checkErrorAndSendToast", [err.response, "error"]);
        });
    });
  },

  logout({ dispatch, commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(
          axios.defaults.baseURL + apis.LOGOUT,
          {},
          {
            headers: {
              Authorization: "Bearer " + payload.userToken,
            },
          }
        )
        .then((res) => {
          resolve(res);
          commit("UPDATE_LOADING_STATE", false);

          localStorage.removeItem("token");
          localStorage.removeItem("user");
          router.push("/").then(() => {
            router.go();
          });
          
          dispatch("checkErrorAndSendToast", [
            res.data.message,
            "success",
          ]);
        })
        .catch((err) => {
          reject(err);
          dispatch("checkErrorAndSendToast", [err.response, "error"]);
        });
    });
  },

  forgotPassword({ dispatch }, payload) {
    const formData = {
      email: payload.email
    }
    return new Promise((resolve, reject) => {
      axios
        .post(axios.defaults.baseURL + apis.FORGOT_PASSWORD, formData, {
          headers: {
            'Content-Type': 'application/json'
          }
        })
        .then((res) => {
          resolve(res);
          dispatch("checkErrorAndSendToast", [res.data.message, "success"]);
        })
        .catch((err) => {
          dispatch("checkErrorAndSendToast", [err.response, "error"]);
          reject(err);
        });
    });
  },

  resetPassword({ dispatch }, payload) {
    const formData = {
      new_password: payload.new_password
    };
    return new Promise((resolve, reject) => {
      axios
        .post(axios.defaults.baseURL + apis.RESET_PASSWORD + payload.token, formData, {
          headers: {
            'Content-Type': 'application/json'
          }
        })
        .then((res) => {
          router.push("/").then(() => {
            router.go();
          });

          resolve(res);
          dispatch("checkErrorAndSendToast", [res.data.message, "success"]);
        })
        .catch((err) => {
          dispatch("checkErrorAndSendToast", [err.response, "error"]);
          reject(err);
        });
    });
  },
};

const getters = {
  // Additional getters if needed
  auth(state) {
    return state.isLoggedIn;
  },
  Layout(state) {
    return state.componentLayout;
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
};
