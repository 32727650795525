export const apis = {
    //Login
    REGISTER_USER: 'user/addone',
    LOGIN: 'user/login',
    LOGIN_WITH_GOOGLE: 'user/glogin',
    LOGOUT: 'user/logout',
    GET_USER_BY_ID: 'user/getuserbyid/',
    UPDATE_USER: 'user/update',
    GET_PROFILE_PICTURE: 'static/',
    // UPDATE_PROFILE_PICTURE: `user/${id}/upload/avatar`,
    USER: 'user/',
    UPDATE_PROFILE_PICTURE_ID: 'upload/avatar',
    DELETE_USER: 'user/delete/',


    ADD_CONTACT: 'contact/add',


    ADD_QUIRIES: 'inquiries/add',
    UPDATE_QUIRIES: 'update/inquiries',
    GET_INQUIRIES: 'getinquiries',
    GET_INQUIRY_BY_ID: 'getinquiriesbyid/',
    GET_INQUIRY_BY_USER_ID: 'getinquiriesbyuserid/',
    DELETE_INQUIRY_BY_ID: 'deleteinquiries/',

    ADD_RESPONSE: 'response/add',
    GET_RESPONSE: 'getresponse',
    GET_RESPONSE_BY_USER_ID: 'getresponsebyuserid/',
    UPDATE_RESPONSE: 'updateresponse',
    GET_ALL_RESPONSE: 'getresponse',
    GET_RESPONSE_BY_ID: 'getresponsebyid/',
    GET_RESPONSE_BY_INQUIRY_ID: 'getresponsebyinqid/',
    DELETE_RESPONSE_BY_ID: 'deleteresponse/',

    ADD_BLOG: 'admin/addblog',
    UPDATE_BLOG: 'admin/updateblog',
    GET_BLOGS: 'getblogs',
    GET_BLOGS_BY_ID: 'getblogsbyid/',
    DELETE_BLOG: 'admin/deleteblog',


    ADD_NEWSLETTER: 'newsletter/add',
    GET_REWARDS: 'get/rewards',

    FORGOT_PASSWORD: 'forgot_password',
    RESET_PASSWORD: 'reset_password/',


    RECENT_FINDS: 'get/recent_finds',
    GET_NOTIFICATIONS: 'get-notification/',
    MARK_AS_READ: 'notification/mark-as-read',
    MARK_AS_ALL_READ: 'notification/mark-as-all-read',


};